.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.form-control,
.form-select,
.input-group-text {
  transition: all 0.3s ease;
}

/* Active state styling */
.form-control:not(:disabled),
.form-select:not(:disabled) {
  background-color: #1a1f2c !important;
  border: 1px solid #364060 !important;
  color: #e9ecef !important;
  box-shadow: none;
}

.form-control:not(:disabled):focus,
.form-select:not(:disabled):focus {
  border-color: #3699ff !important;
  box-shadow: 0 0 0 0.15rem rgba(54, 153, 255, 0.25) !important;
  background-color: #1a1f2c !important;
}

/* Disabled state styling */
.form-control:disabled,
.form-select:disabled {
  background-color: #12151e !important;
  border: 1px solid #1e2231 !important;
  color: #6c757d !important;
  cursor: not-allowed;
  opacity: 0.75;
  user-select: none;
}

/* Input group styling */
.input-group-text {
  background-color: #1a1f2c;
  border-color: #364060;
  color: #6c757d;
}

.input-group .form-control:disabled + .input-group-text {
  background-color: #12151e;
  border-color: #1e2231;
  opacity: 0.75;
}

/* Label styling */
.form-label {
  color: #a6b0cf;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

/* Specific styles for editing mode */
.editing-form .form-control:not(:disabled),
.editing-form .form-select:not(:disabled) {
  border-left: 3px solid #3699ff !important;
  padding-left: calc(0.875rem - 1px);
}

.editing-form .form-control:not(:disabled):hover,
.editing-form .form-select:not(:disabled):hover {
  background-color: #1e2538 !important;
}

/* Add hover effect for selects */
.form-select:not(:disabled) {
  cursor: pointer;
}

/* Style the select arrow */
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23a6b0cf' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.form-select:disabled {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

/* Section headers */
.form-section-header {
  color: #3699ff;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #1e2231;
}

/* Read-only field specific styling */
.form-control[readonly] {
  background-color: #12151e !important;
  border-color: #1e2231 !important;
  color: #6c757d !important;
  border-left: 3px solid #6c757d !important;
}

.inRange {
  background-color: #1e2538 !important;
  width: 100%;
}
